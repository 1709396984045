import React from 'react'
import Modal from 'react-modal';


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


const DeleteHistoryPopup = (props) => {
  

const hanldeClosePopup = () =>{
    props.setOpenHistoryPopup(false)
}

const handleDeletechat = () =>{
    props.handleDeleteHistory()
    props.setClearMemory(false)
    window.location.reload()
}




  return (
    <>
     <Modal
        isOpen={props.openHistoryPopup}
        onRequestClose={hanldeClosePopup}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='delete_history'>
            <div className='delete_heading'>
                <h3>Delete Confirmation</h3>
            </div>
            <div className='delete_content'>
                <p>Are you sure to delete all chat history with this bot? This cannot be undone.</p>
            </div>
      <div className='buttons'>
        <button className='custom_btn clear_btn' type='submit' onClick={hanldeClosePopup}>Cancel</button>
        <button className='custom_btn delete_history' type='submit' onClick={handleDeletechat}>Delete </button>
      </div>   
      </div>   
      </Modal> 
    </>
  )
}

export default DeleteHistoryPopup
