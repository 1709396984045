import axios from "axios";

const Base_Url = process.env.REACT_APP_URL;

export const SET_USER_POINTS = 'SET_USER_POINTS';

export const setUserPoints = (points) => ({
  type: SET_USER_POINTS,
  points,
});

export const fetchUserPoints = (userId) => async (dispatch) => {
  try {
    const response = await axios.get(`${Base_Url}/users/getuser/${userId}`);
    const points = response.data.user.points;
    console.log("points>>>",points)
    dispatch(setUserPoints(points));
  } catch (error) {
    // Handle errors if necessary
    console.log("error>>>>>>>",error)
  }
};