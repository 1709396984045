import { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
// import {GoogleLogin} from 'react-google-login';
import "../login-signup/login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const Client_Secret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

const Login = ({ setIsAuthenticated }) => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log("BaseUrl>>>>>>>>>>>", Base_Url);
  const Discord_client_id = process.env.REACT_APP_DISCORD_CLIENT_ID;
  const REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;

  const navigate = useNavigate();
  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((values) => ({ ...values, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    let valid = true;

    if (!input.email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "メールアドレスは必須です*",
      }));
      valid = false;
    } else if (!input.password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "パスワードが必要*",
      }));
      valid = false;
    } else {
      const object = {
        email: input.email,
        password: input.password,
      };
      try {
        const result = await axios.post(`${Base_Url}/users/login`, object);
        console.log("result>>>>", result);
        if (result.data.success === true) {
          setIsAuthenticated(true);
          localStorage.setItem("isAuthenticated", "true");
          const first_name = result.data.user.firstname;
          const user_id = result.data.user._id;
          const userEmail = result.data.user.email;
          const points = result.data.user.points;
          const jwtToken = result.data.token;
          localStorage.setItem("username", first_name);
          localStorage.setItem("userId", user_id);
          localStorage.setItem("email", userEmail);
          localStorage.setItem("points", points);
          localStorage.setItem("jwtToken", jwtToken);
          navigate("/home");
        }
      } catch (error) {
        console.log("error>>>", error.response.data);
        const errorMessage = error.response.data.error;
        toast.error(errorMessage);
      }
    }
  };

  // const onSuccess = async (res) => {
  //   console.log("res>>>>>",res)
  //   console.log("gdgffgj>>>>>>", res.profileObj)
  //   console.log(">>>>>>>>>>>>>>", res.accessToken);
  //   try {
  //     let obj = {
  //       email: res.profileObj.email,
  //       password: res.profileObj.googleId,
  //     }
  //     const result = await axios.post("http://192.168.1.5:8081/users/login", obj)
  //     console.log(">result>>>", result)
  //     if(result.data.success===true){
  //       const first_name = result.data.user.firstname
  //       const user_id = result.data.user._id
  //        localStorage.setItem("username", (first_name))
  //        localStorage.setItem("userId", (user_id))
  //          navigate('/home')
  //    }
  //   }
  //   catch (error) {
  //     console.log(error)
  //     console.log("status>>>", error.response.status)
  //     console.log("message>>>", error.response.data.message)

  //     if (error.response.status === 400) {
  //       console.log(error.response.data.message)
  //       toast.error(error.response.data.message)
  //     }
  //   }
  // }
  // const onFailure = (res) => {
  //   console.log("Login Failer!", res)
  // }

  const handleDiscordLogin = (e) => {
    e.preventDefault();
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${Discord_client_id}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=identify%20email`;
  };

  return (
    <>
      <ToastContainer />
      <div className="login-body">
        <div className="login-cards">
          <form>
            <div className="heading">
              <h3>ログインページ</h3>
            </div>
            {/* <GoogleLogin
										clientId={clientId}
										buttonText="Signin with Google"
										onSuccess={onSuccess}
										onFailure={onFailure}
										cookiePolicy={'single_host_origin'}
										prompt="select_account"
									/> */}
            {/* <br/> */}
            {/* <button type='submit' className='btn btn-primary' onClick={handleDiscordLogin}>Login with Discord</button> */}
            {/* <p className='or_content'>OR</p> */}
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                name="email"
                placeholder="メールアドレスを入力"
                value={input.email}
                onChange={handleChange}
              />
              <div className="error-message">{errors.email}</div>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="パスワード入力"
                value={input.password}
                onChange={handleChange}
              />
              <div className="error-message">{errors.password}</div>
              <Link className="log-link" to="/update-password">
                パスワードを忘れた ?
              </Link>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleLogin}
            >
              続ける
            </button>
            <p className="login-bottom">
              アカウントを持っていない ?<Link to="/signup">会員登録</Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
