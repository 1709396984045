import { SET_USER_POINTS } from '../actions/index.js';

const initialState = {
  points: 0,
};

const pointReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_POINTS:
      return {
        ...state,
        points: action.points,
      };
    default:
      return state;
  }
};

export default pointReducer;