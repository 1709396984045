import React,{useState} from 'react';
import Modal from 'react-modal';
import axios from 'axios'
import DeleteHistoryPopup from './DeleteHistoryPopup';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: 'rgb(217 208 208)',
    },
  };

  

const DeletePopup = (props) => {

  const Base_Url = process.env.REACT_APP_URL;
    const chatRoomId = props.chatroomId
    console.log("roomid>>>",chatRoomId)
    console.log("propschatroomid>>>>",props.chatroomId)
    const [openHistoryPopup, setOpenHistoryPopup] = useState(false)

const hanldeClosePopup = ()=>{
    props.setOpenDeletePopUp(false)
}

const handleClearMemory = async()=>{
  try {
    const result = await axios.delete(`/chat/deleteChat/${chatRoomId}/messages`)
    console.log("result>>>>",result)
    if(result){
        props.setClearMemory(true)
        props.setOpenDeletePopUp(false)
        window.location.reload()
    }
  } catch (error) {
    console.log("error>>>>",error)
  }
}

const handleOpenPopup = ()=>{
    setOpenHistoryPopup(true)
    props.setOpenDeletePopUp(false)
}

  return (
    <>
    <DeleteHistoryPopup setOpenHistoryPopup={setOpenHistoryPopup} openHistoryPopup={openHistoryPopup}  handleDeleteHistory={props.handleDeleteHistory} setClearMemory={props.setClearMemory} />
      <Modal 
        isOpen={props.openDeletePopUp}
        onRequestClose={hanldeClosePopup}
        style={customStyles}
        contentLabel="Example Modal" 
      >
      <div className='buttons'>
        <button className='custom_btn share_btn' type='submit'>Share Chat Records</button>
        <button className='custom_btn clear_btn' type='submit' onClick={handleClearMemory}>会話履歴を削除する</button>
        {/* <button className='custom_btn delete_history' type='submit' onClick={handleOpenPopup}>Delete ChatHistory</button> */}
      </div>      
      </Modal>
    </>
  )
}

export default DeletePopup
