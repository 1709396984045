import React, { useEffect, useCallback, useState } from "react";
import { useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";
import { Loader } from "../Loader";

const CreateCharacter = () => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log("BaseUrl>>>>>>>>>>>", Base_Url);

  const dispatch = useDispatch();

  const UserId = localStorage.getItem("userId");
  const userId = localStorage.getItem("userId");
  const jwtToken = localStorage.getItem("jwtToken");

  const navigate = useNavigate("");

  const [pdffile, setPdfFile] = useState(null);
  const [imgSrc, setImgSrc] = useState("");
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatedImageType, setGeneratedImageType] = useState("Regular");
  const [input, setInput] = useState({
    image: "",
    name: "",
    description: "",
    prompt:
      "you  are a passionate and powerful ai model with high IQ level and you always provide accurate information after considering universal data .",
    introMessage: "",
    category: "おすすめ",
    voice: "2.四国めたん",
    visibility: "公開：誰でもチャット可能",
    url: "",
  });
  console.log("input>>>", input.voice);
  const [characterNameSpace, setCharacterNameSpace] = useState("");
  const [bounce, setBounce] = useState(true);
  const [pdf, setPdf] = useState("");
  console.log("pdf>>>>>", pdf);

  const [isLoading, setIsLoading] = useState(false);

  const pdfRef = useRef("");
  const ImageRef = useRef("");
  const location = useLocation("");
  console.log("characterlocations>>>>>>", location);
  const characterid = location?.state?.id;

  useEffect(() => {
    if (location.state !== null) {
      setInput({
        name: location?.state?.characterData?.characterName,
        description: location?.state?.characterData?.characterDescription,
        prompt: location?.state?.characterData?.characterPrompt,
        introMessage: location?.state?.characterData?.charcterIntro,
        category: location?.state?.characterData?.characterCategory,
        voice: location?.state?.characterData?.characterVoice,
        visibility: location?.state?.characterData?.characterVisibility,
        url: location?.state?.characterData?.characterUrl,
        image: location?.state?.characterData?.characterImage,
      });
      setImgSrc(location?.state?.characterData.characterImage);
      setCharacterNameSpace(location?.state?.characterData?.characterNameSpace);
      setPdf(location?.state?.characterData?.pdf);
    }
  }, [location?.state]);

  console.log("file>>>>>>>>", pdffile);

  const handleImageChange = (event) => {
    event.preventDefault();
  };

  const handlePdfChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selectedfiles>>>>>>>>>", selectedFile);
    setPdfFile(selectedFile);
    if (selectedFile) {
      setIsLoading(false);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 90000);
    }
  };

  const handleUploadPdf = () => {
    pdfRef.current.click();
    setIsLoading(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleNavigate = (
    _id,
    image,
    name,
    description,
    prompt,
    introMessage,
    userId,
    category,
    voice,
    visibility,
    characterNameSpace,
    url,
    pdf
  ) => {
    console.log("idss>>>>>>>", _id, image, name, description);

    const detail = {
      _id: _id,
      name: name,
      image: image,
      description: description,
      prompt: prompt,
      introMessage: introMessage,
      userId: userId,
      category: category,
      voice: voice,
      visibility: visibility,
      characterNameSpace: characterNameSpace,
      characterUrl: url,
      pdf: pdf,
    };

    navigate("/chat", { state: { data: detail } });
  };

  // create character
  const handleCreateCharacter = async (e) => {
    console.log("input>>>>>", "aaaaaaa");
    e.preventDefault();
    setBounce(false);

    if (!input.name || !input.prompt || imgSrc === "") {
      toast.error("名前とプロンプトは必須フィールドです*");
    } else {
      const formData = new FormData();
      formData.append("userId", UserId);
      formData.append("image", imgSrc);
      formData.append("name", input?.name);
      formData.append("description", input.description);
      formData.append("prompt", input?.prompt);
      formData.append("introMessage", input.introMessage);
      formData.append("category", input?.category);
      formData.append("voice", input?.voice);
      formData.append("visibility", input?.visibility);
      formData.append("url", input?.url);
      formData.append("pdf", pdffile?.name);

      console.log("formdata>>>>>", formData);

      try {
        const result = await axios.post(
          `${Base_Url}/character/create_character`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log("Characterresult>>>>>", result);
        const characterNameSpace = result.data.characterData.characterNameSpace;
        const characterid = result.data.characterData._id;

        let pdfresult;
        if (pdffile) {
          const pdfformData = new FormData();
          pdfformData.append("pdf", pdffile);
          pdfformData.append("userId", userId);
          pdfformData.append("characterid", characterid);
          pdfformData.append("characterNameSpace", characterNameSpace);

          pdfresult = await axios.post(
            `${Base_Url}/character/upload_pdf`,
            pdfformData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log("pdfresult>>>>>>>>", pdfresult);
        }

        if (result.data.success === true) {
          setBounce(true);
          dispatch(actionCreators.fetchUserPoints(userId));
          toast.success("character created successfully");
          handleNavigate(
            result.data.characterData._id,
            result.data.characterData.image,
            result.data.characterData.name,
            result.data.characterData.description,
            result.data.characterData.prompt,
            result.data.characterData.introMessage,
            result.data.characterData.userId,
            result.data.characterData.category,
            result.data.characterData.voice,
            result.data.characterData.visibility,
            result.data.characterData.characterNameSpace,
            result.data.characterData.url,
            result.data.characterData.pdf
          );
        }
      } catch (error) {
        setBounce(true);
        console.log("error>>>", error);
        toast.error(error.response.data.error);
      }
    }
  };

  // delete character
  const deleteCharacter = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.put(
        `${Base_Url}/character/deleteCharacter/${characterid}?UserId=${UserId}`
      );
      console.log("result>>>>>", result);
      if (result.data.success === true) {
        toast.success("character deleted successfully");
        navigate("/home");
      }
    } catch (error) {
      console.log("error>>>>>>", error);
      toast.error(error.response.data.error);
    }
  };

  // Edit charater
  const handleEditCharacter = async (e) => {
    e.preventDefault();
    setBounce(false);

    if (!input.name || !input.prompt) {
      toast.error("Name and Prompt are required fields");
    } else {
      const formData = new FormData();
      formData.append("image", imgSrc);
      formData.append("name", input?.name);
      formData.append("description", input?.description);
      formData.append("prompt", input?.prompt);
      formData.append("introMessage", input?.introMessage);
      formData.append("category", input?.category);
      formData.append("voice", input?.voice);
      formData.append("visibility", input?.visibility);
      formData.append("url", input?.url);
      formData.append("characterNameSpace", characterNameSpace);
      formData.append("pdf", pdffile?.name);

      try {
        //    if(pdf !== pdffile.name){
        let pdfresult;
        if (pdffile) {
          const pdfformData = new FormData();
          pdfformData.append("pdf", pdffile);
          pdfformData.append("userId", userId);
          pdfformData.append("characterNameSpace", characterNameSpace);

          pdfresult = await axios.post(
            `${Base_Url}/character/upload_pdf`,
            pdfformData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          console.log("pdfresult>>>>>>>>", pdfresult);
        }

        //    }
        const result = await axios.put(
          `${Base_Url}/character/edit_character/${characterid}`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log("result>>>>>", result);

        if (result.data.success === true) {
          setBounce(true);
          toast.success("character updated successfully");

          handleNavigate(
            result.data.characterData._id,
            result.data.characterData.image,
            result.data.characterData.name,
            result.data.characterData.description,
            result.data.characterData.prompt,
            result.data.characterData.introMessage,
            result.data.characterData.userId,
            result.data.characterData.category,
            result.data.characterData.voice,
            result.data.characterData.visibility,
            result.data.characterData.characterNameSpace,
            result.data.characterData.url,
            result.data.characterData.pdf
          );
        }
      } catch (error) {
        console.log("error>>>>>>", error);
        toast.error(error.response.data.error);
      }
    }
  };

  const handleGetGeneratedImage = async (type, event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${Base_Url}/character/gen_character_image?imageType=${type}&userId=${UserId}`
      );
      const imageUrl = result.data.imageUrl;
      setImgSrc(imageUrl);
      setGeneratedImages((genImgs) => [...genImgs, imageUrl]);
      dispatch(actionCreators.fetchUserPoints(userId));
      toast.success("Image generated successfully");
      setIsLoading(false);
    } catch (error) {
      console.log("error>>>>>>", error);
      console.log("error resp", error.response);
      toast.error(error.response.data.error);
    }
  };

  const setSelectedOption = (value) => {
    setGeneratedImageType(value);
  };

  const getGeneratdImages = useCallback(async () => {
    try {
      const result = await axios.get(
        `${Base_Url}/character/get-character-images?userId=${UserId}`
      );
      const imageUrls = result.data.urls;
      setGeneratedImages(imageUrls);
    } catch (error) {
      console.log("error>>>>>>", error);
      toast.error(error.response.data.error);
    }
  }, [Base_Url, UserId]);

  useEffect(() => {
    getGeneratdImages();
  }, [getGeneratdImages]);

  return (
    <>
      <ToastContainer />
      <div className="character_outer inner-screen">
        <div className="character_head">
          {!characterid ? (
            <h2>キャラクターを作る</h2>
          ) : (
            <h2>キャラクターを編集する</h2>
          )}
        </div>
        <div className="character_fields">
          <div className="character_form">
            <form>
              <div className="form-group top-image">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "433px",
                      gap: "20px",
                    }}
                  >
                    <label for="image">イメージ生成*</label>
                    <div className="img_label_hint">
                      「ノーマル」か「R指定」を選択して、作成したいキャラクターのイメージをAIで生成してください。ランダムでイメージが表示されます。
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "67px",
                      }}
                    >
                      <div
                        onChange={(event) =>
                          setSelectedOption(event.target.value)
                        }
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <input
                            type="radio"
                            id="regular"
                            value="Regular"
                            name="imageType"
                            defaultChecked
                          />
                          ノーマル
                        </label>
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <input
                            type="radio"
                            id="special"
                            value="Special"
                            name="imageType"
                          />
                          R指定
                        </label>
                      </div>
                      <button
                        onClick={(event) =>
                          handleGetGeneratedImage(generatedImageType, event)
                        }
                        style={{
                          background: "#3A5AFF",
                        }}
                      >
                        生成する
                      </button>
                      {isLoading && <Loader className="size-6" />}
                    </div>
                  </div>
                  <div
                    style={{
                      marginRight: "150px",
                    }}
                  >
                    {imgSrc === "" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "336px",
                          height: "544px",
                          border: "1px solid #000",
                          borderRadius: "34px",
                        }}
                      >
                        Generated Character Image
                      </div>
                    )}
                    {imgSrc !== "" && (
                      <img
                        src={imgSrc}
                        alt=""
                        style={{
                          maxWidth: "336px",
                          maxHeight: "544px",
                          border: "1px solid #000",
                          borderRadius: "34px",
                        }}
                      />
                    )}
                  </div>
                </div>{" "}
              </div>

              <div
                id="generatedImages"
                className={`${
                  !generatedImages.length ? "hidden" : ""
                } flex flex-row gap-2 w-full overflow-auto overflow-x-scroll my-2`}
              >
                {generatedImages.map((imageUrl, index) => {
                  return (
                    <div
                      onClick={() => setImgSrc(imageUrl)}
                      className="cursor-pointer"
                    >
                      <img
                        key={index}
                        src={imageUrl}
                        alt=""
                        style={{
                          maxWidth: "200px",
                          maxHeight: "200px",
                          border: "1px solid #000",
                          borderRadius: "34px",
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div className="form_group">
                <label for="name">名前*</label>
                <p>キャラクターの名前を4～20文字で入力してください。.</p>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={input.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form_group">
                <label for="description">説明</label>
                <p>キャラクターの説明を入力してください。.</p>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={input.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form_group">
                <label for="prompt">プロンプト*</label>
                <p>
                  キャラクターはここに入力されたプロンプトを元にユーザーに返答します。口調や性格、趣味や好みなど、工夫して入力することで、さまざまな特徴を持つキャラクターを作ることができます。プロンプトの例はこちら.
                </p>
                <input
                  type="text"
                  className="form-control"
                  name="prompt"
                  value={input.prompt}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form_group">
                <label for="message">イントロメッセージ</label>
                <p>
                  キャラクターとの会話はここに入力するメッセージから始まります。.
                </p>
                <input
                  type="text"
                  className="form-control"
                  name="introMessage"
                  value={input.introMessage}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form_group">
                <label for="category">カテゴリー</label>
                <p>あなたのキャラクターが属するカテゴリーを選んでください。.</p>
                {/* <input type='text' className='form-control'  name='category' value={input.category} onChange={handleInputChange}/> */}
                <select
                  className="form-control"
                  name="category"
                  value={input.category}
                  onChange={handleInputChange}
                >
                  {/* <option>おすすめ</option> */}
                  <option>アシスタント</option>
                  <option>学習</option>
                  <option>ゲーム</option>
                  <option>アニメ</option>
                  <option>ディスカッション</option>
                  <option>宗教</option>
                  <option>ストーリー</option>
                  <option>コメディ</option>
                  <option>歴史</option>
                  <option>その他</option>
                </select>
              </div>
              <div className="form_group">
                <label for="voice">ボイス</label>
                <p>キャラクターの声を決めてください。.</p>
                {/* <input type='text' className='form-control' name='voice' value={input.voice} onChange={handleInputChange} /> */}
                <select
                  className="form-control"
                  name="voice"
                  value={input.voice}
                  onChange={handleInputChange}
                >
                  <option value="2.四国めたん">四国めたん</option>
                  <option value="5.ずんだもん">ずんだもん</option>
                  <option value="8.春日部つむぎ">春日部つむぎ</option>
                  {/* <option value="10.雨晴はう">雨晴はう</option> */}
                  <option value="9.波音リツ">波音リツ</option>
                  <option value="11.玄野武宏">玄野武宏</option>
                  <option value="12.白上虎太郎">白上虎太郎</option>
                  {/* <option value="13.青山龍星">青山龍星</option> */}
                  <option value="14.冥鳴ひまり">冥鳴ひまり</option>
                  <option value="16.九州そら">九州そら</option>
                  {/* <option value="20.もち子さん">もち子さん</option> */}
                  <option value="21.剣崎雌雄">剣崎雌雄</option>
                  <option value="23.WhiteCUL">WhiteCUL</option>
                  {/* <option value="27.後鬼">後鬼</option> */}
                  {/* <option value="29.No.7">No.7</option> */}
                  <option value="42.ちび式じい">ちび式じい</option>
                  <option value="43.櫻歌ミコ">櫻歌ミコ</option>
                  <option value="46.小夜/SAYO">小夜/SAYO</option>
                  {/* <option value="47.ナースロボ＿タイプＴ">ナースロボ＿タイプＴ</option> */}
                  <option value="51.†聖騎士 紅桜†">†聖騎士 紅桜†</option>
                  <option value="52.雀松朱司">雀松朱司</option>
                  <option value="53.麒ヶ島宗麟">麒ヶ島宗麟</option>
                  <option value="54.春歌ナナ">春歌ナナ</option>
                  {/* <option value="57.猫使アル">猫使アル</option> */}
                  {/* <option value="58.猫使ビィ">猫使ビィ</option> */}
                  <option value="61.中国うさぎ">中国うさぎ</option>
                  <option value="67.栗田まろん">栗田まろん</option>
                  {/* <option value="68.あいえるたん">あいえるたん</option> */}
                  <option value="69.満別花丸">満別花丸</option>
                  <option value="74.琴詠ニア">琴詠ニア</option>
                </select>
              </div>
              <div className="form_group">
                <label for="visibility">公開・非公開</label>
                <p>
                  公開：誰でもチャットできます。　非公開：あなただけがチャットできます。
                </p>
                {/* <input type='text' className='form-control' name='visibility' value={input.visibility} onChange={handleInputChange}/> */}
                <select
                  className="form-control"
                  name="visibility"
                  value={input.visibility}
                  onChange={handleInputChange}
                >
                  <option>公開：誰でもチャット可能</option>
                  <option>プライベート:あなただけがチャットできます</option>
                </select>
              </div>
              {/* <div className='form_group'>
                                <label for='visibility'>知識・情報</label>
                                <p>キャラクターは、以下のURLとPDFの内容に基づいて回答するようになります。.</p>
                                <div className='sub-input'>
                                    <label for='url'>URL</label>
                                    <span><input type="text" name='url' value={input.url} onChange={handleInputChange} /> <button type='button'>インポート</button></span>
                                </div>
                                <div className='sub-input pdf_upload_file'>
                                    <label for='pdf'>PDF</label>
                                    <span><input type="file" accept=".pdf" name='pdf' ref={pdfRef} className='form-control' onChange={handlePdfChange} />
                                    <button type='button' onClick={handleUploadPdf}>{loader ? <span class="loader"></span> :"インポート" }</button> 
                                        {(pdffile || pdf) && <div className='file_label'>{pdffile?.name || pdf}</div>}</span>
                                </div>
                            </div> */}
            </form>
            {!characterid ? (
              <div className="character_button">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateCharacter}
                >
                  {" "}
                  {bounce ? (
                    "作成"
                  ) : (
                    <div className="bouncer">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  )}
                </button>
              </div>
            ) : (
              <div className="edit_button">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleEditCharacter}
                >
                  {bounce ? (
                    "保存"
                  ) : (
                    <div className="bouncer">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={deleteCharacter}
                >
                  キャラクターを削除する
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCharacter;
