import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import HeaderPopup from "./HeaderPopup";
import Logo from "../images/aiaipie-logo.svg";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";

const Header = () => {
  const Base_Url = process.env.REACT_APP_URL;

  const dispatch = useDispatch();

  const Username = localStorage.getItem("username");
  const userEmail = localStorage.getItem("email");
  const userId = localStorage.getItem("userId");

  const [toggleTrue, setToggleTrue] = useState(false);
  const [points, setPoints] = useState(0);

  const [cryptoOpen, setCryptoOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const navigate = useNavigate("");

  const point = useSelector((state) => state.points);
  console.log("pointredux>>>>>", point);

  const handleOpenToggle = (e) => {
    setToggleTrue((toggleTrue) => !toggleTrue);
  };

  const handleLogout = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    localStorage.removeItem("isAuthenticated");
    navigate("/");
  };

  useEffect(() => {
    const getuser = async () => {
      const user = await axios.get(`${Base_Url}/users/getuser/${userId}`);
      console.log("user>>>>>>>>>", user);
      setPoints(user?.data?.user?.points);
      if (user?.data?.user?.points === 0) {
        handleOpenCryptoPopup();
      }
    };
    getuser();
  }, [Base_Url, userId]);

  useEffect(() => {
    dispatch(actionCreators.fetchUserPoints(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    const getuser = async () => {
      const user = await axios.get(`${Base_Url}/users/getUserDetail/${userId}`);
      console.log("user>>>>>>>>>", user);
    };
    getuser();
  }, [Base_Url, userId]);

  const handleOpenCryptoPopup = () => {
    setCryptoOpen(true);
  };
  const handleOpenPaymentPopup = () => {
    setPaymentOpen(true);
  };

  // const handleGo =()=>{
  //   window.location.href= 'https://twitter.com/AiAiPieCom';
  // }

  const handlGoLinks = (value) => {
    console.log(value);
    if (value == "プライバシーとポリシー") {
      window.location.href = "https://help.aiaipie.com/kiyaku/puraibashporish";
    } else if (value == "利用規約") {
      window.location.href = "https://help.aiaipie.com/kiyaku/li-yong-gui-yao";
    } else if (value == "ヘルプ") {
      window.location.href = "https://help.aiaipie.com/";
    } else if (value == "Q&A") {
      window.location.href = "https://help.aiaipie.com/others/q-a";
    }
  };

  const handleGoHome = () => {
    navigate("/home");
  };

  return (
    <>
      <HeaderPopup open={paymentOpen} setOpen={setPaymentOpen} />
      <div className="header_content">
        <div className="logo" onClick={handleGoHome}>
          <img className="logo_img" src={Logo} alt="" /> <span>Beta</span>
        </div>
        {/* <div className='icon' onClick={handleGo}>
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="25" viewBox="0 0 31 25" fill="none">
        <path d="M29.7084 0.924366C28.4715 1.79686 27.102 2.46417 25.6526 2.90062C24.8747 2.00615 23.8408 1.37218 22.6908 1.08444C21.5409 0.796698 20.3303 0.869077 19.2228 1.29178C18.1153 1.71449 17.1643 2.46713 16.4985 3.44792C15.8327 4.4287 15.4842 5.5903 15.5001 6.77561V8.06728C13.2301 8.12614 10.9809 7.62271 8.95263 6.60182C6.92437 5.58093 5.18007 4.07427 3.87508 2.21603C3.87508 2.21603 -1.29158 13.841 10.3334 19.0077C7.67326 20.8134 4.50432 21.7188 1.29175 21.591C12.9167 28.0494 27.1251 21.591 27.1251 6.73686C27.1239 6.37708 27.0893 6.01817 27.0217 5.66478C28.34 4.36471 29.2703 2.72329 29.7084 0.924366Z" fill="black" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div> */}

        <div className="profile_name">
          <p className="points">{point?.points} points</p>
          <button className="btn btn-primary" onClick={handleOpenPaymentPopup}>
            アップグレード
          </button>
          <div className="profile-pic" onClick={handleOpenToggle}>
            <span>{Username?.charAt(0)?.toUpperCase()}</span>
          </div>
          {toggleTrue && (
            <div className="toggle_screen">
              <div className="userDetails">
                <p>{Username}</p>
                <p>{userEmail}</p>
                <hr />
                <ul>
                  <li onClick={(e) => handlGoLinks("プライバシーとポリシー")}>
                    プライバシーとポリシー
                  </li>
                  <li onClick={(e) => handlGoLinks("利用規約")}>利用規約</li>
                  <li onClick={(e) => handlGoLinks("ヘルプ")}>ヘルプ</li>
                  <li onClick={(e) => handlGoLinks("Q&A")}>Q&A</li>
                  <li onClick={handleLogout}>ログアウト</li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
