import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import PricingTable from "../home/PricingTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#fff",
  },
};

const HeaderPopup = (props) => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log(process.env);
  const userId = localStorage.getItem("userId");
  console.log("userId>>", userId);

  const [isPatreon, setIsPatreon] = useState(false);
  const [isPricingTableOpen, setIsPricingTableOpen] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [expirationTime, setExpirationTime] = useState("");

  const calculateRemainingTime = () => {
    const now = new Date();
    const expiration = new Date(expirationTime);
    const difference = expiration - now;

    return difference;
  };

  // const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  //close popup function
  const hanldeClosePopup = () => {
    props.setOpen(false);
  };

  //payment function
  const handleCryptoPaymentLink = async (e) => {
    e.preventDefault();
    setIsPricingTableOpen(true);
    setIsPatreon(false);
    hanldeClosePopup();
  };

  //patreon payment link
  const handlePatreonPaymentLink = async (e) => {
    e.preventDefault();
    setIsPatreon(true);
    setIsPricingTableOpen(true);
    hanldeClosePopup();
  };

  useEffect(() => {
    const getuserSubscription = async () => {
      const userSubscription = await axios.get(
        `${Base_Url}/users/getuser/${userId}`
      );
      console.log("userSubscription>>>>>>>>>", userSubscription);
      setExpirationTime(
        userSubscription?.data?.user?.subscriptionExpirationDate
      );
      setSubscriptionStatus(userSubscription?.data?.user?.subscription);
    };
    getuserSubscription();
  }, [Base_Url, userId]);

  return (
    <>
      <PricingTable
        open={isPricingTableOpen}
        setOpen={setIsPricingTableOpen}
        isPatreon={isPatreon}
      />
      <Modal
        isOpen={props.open}
        onRequestClose={hanldeClosePopup}
        style={customStyles}
        contentLabel="Payment Modal"
        className="header_popup"
      >
        <div className="popup_layout">
          <div className="closePopup" onClick={hanldeClosePopup}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
            >
              <path
                d="M28.125 16.875L16.875 28.125"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.875 16.875L28.125 28.125"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="heading_pop">
            {props.isChatScreen ? <p>ポイントが足りません。</p> : null}
            <p style={{ marginBottom: "30px" }}>
              ポイントを購入して思いっきり楽しもう！！
              30ドルから簡単に購入できます。
            </p>

            <p>下から決済方法を選択してください。</p>
          </div>
          <div className="List_plan">
            {/* <button className='btn btn-primary' onClick={handlePaymentLink}>Subscribe</button> */}
            <button
              className="btn btn-primary"
              disabled={subscriptionStatus}
              onClick={handlePatreonPaymentLink}
            >
              クレジットカードで購入する
            </button>
            <button
              className="btn btn-primary"
              disabled={subscriptionStatus}
              onClick={handleCryptoPaymentLink}
            >
              暗号資産で購入する
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default HeaderPopup;
