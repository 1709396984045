import { useState, useEffect, useRef } from "react";
import axios from "axios";
import "../home/chatscreen.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DeletePopup from "./DeletePopup";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactMarkdown from "react-markdown";
import HeaderPopup from "../sidebar/HeaderPopup";

const ChatScreen = () => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log("BaseUrl>>>>>>>>>>>", Base_Url);

  const dispatch = useDispatch();

  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [audioData, setAudioData] = useState(null);
  const [audioDataArray, setAudioDataArray] = useState([]);
  console.log("audioDataArray>>>>>", audioDataArray);
  const [recording, setRecording] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [userId, setUserId] = useState("");
  const [chatroomId, setChatRoomId] = useState("");
  console.log("chatroomId>>>>", chatroomId);
  const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
  const [clearMemory, setClearMemory] = useState(false);
  const [chatRooms, setChatRooms] = useState("");
  console.log("chatrooms>>>>", chatRooms);
  const [charctername, setCharacterName] = useState(false);
  const [name, setName] = useState("");
  const [characterId, setCharacterId] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [showResponse, setShowResponse] = useState({});
  const [prompt, setPrompt] = useState("");
  const [introMessage, setIntroMessage] = useState("");
  const [characterNameSpace, setCharacterNameSpace] = useState("");
  const [lengthofdiv, setLenghtofDiv] = useState();
  const [buttonShow, setButtonShow] = useState(false);
  const [characterUrl, setCharacterUrl] = useState("");
  const [pdf, setPdf] = useState("");
  const [open, setOpen] = useState(false);
  //groupdetails states
  const [groupmember, setGroupMember] = useState("");
  const [groupId, setGroupId] = useState("");
  const [groupname, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [groupImage, setGroupImage] = useState("");
  const [groupCharcter, setGroupCharcter] = useState("");
  const [isGroupCharcter, setIsGroupCharcter] = useState("");
  const [groupTopic, setGroupTopic] = useState("");

  const mediaRecorderRef = useRef(null);

  console.log(
    "name>>>>>",
    isGroupCharcter,
    groupCharcter,
    groupname,
    groupmember
  );

  const UserId = localStorage.getItem("userId");

  const location = useLocation("");
  console.log("location>>>>>>>>>>", location);

  useEffect(() => {
    const hash = window?.location?.hash;
    console.log("hash>>>>", hash);
    const chatRoomId = hash?.slice(1);
    console.log("chatid>>>>", chatRoomId);
    setChatRoomId(chatRoomId);
  }, []);

  // Get the hash fragment from the URL

  const audioStream = useRef(null);
  const navigate = useNavigate("");

  const handleinputChange = (e) => {
    setInputMessage(e.target.value);
  };

  //function for get default user chat
  useEffect(() => {
    const userIdFromLocalStorage = localStorage.getItem("UserId");
    const chatroomIdFromLocalStorage = localStorage.getItem("ChatroomId");
    setUserId(userIdFromLocalStorage);
    setChatRoomId(chatroomIdFromLocalStorage);
    // const message = localStorage.getItem('intromessage')
    // setIntroMessage(message)
  }, []);

  useEffect(() => {
    if (location?.state?.data) {
      setCharacterId(location?.state?.data?._id);
      setName(location?.state?.data?.name);
      setImage(location?.state?.data?.image);
      setDescription(location?.state?.data?.description);
      setPrompt(location?.state?.data?.prompt);
      setIntroMessage(location?.state?.data?.introMessage);
      setCharacterNameSpace(location?.state?.data?.characterNameSpace);
      setCharacterUrl(location?.state?.data?.characterUrl);
      setPdf(location?.state?.data?.pdf);
      // localStorage.setItem('intromessage',location?.state?.data?.introMessage)
    }
  }, [location?.state?.data]);

  useEffect(() => {
    if (location?.state?.groupdata) {
      setGroupId(location?.state?.groupdata?._id);
      setGroupName(location?.state?.groupdata?.name);
      setGroupImage(location?.state?.groupdata?.image);
      setGroupDescription(location?.state?.groupdata?.description);
      setGroupMember(location?.state?.groupdata?.members);
      setGroupCharcter(location?.state?.groupdata?.botcharacters);
      setIsGroupCharcter(location?.state?.groupdata?.isGroup);
      setGroupTopic(location?.state?.groupdata?.topic);
    }
  }, [location?.state?.groupdata]);

  //function for get localstorage data according to bot
  const handleChatRoom = async (
    name,
    image,
    description,
    prompt,
    chatRoomId,
    characterId,
    characterNameSpace,
    characterUrl,
    characterpdf
  ) => {
    console.log(
      "name>>>>>>>>>>",
      name,
      image,
      description,
      prompt,
      chatRoomId,
      characterId
    );

    setCharacterId(characterId);
    setName(name);
    setImage(image);
    setDescription(description);
    setPrompt(prompt);
    setChatRoomId(chatRoomId);
    setCharacterId(characterId);
    setCharacterNameSpace(characterNameSpace);
    setCharacterUrl(characterUrl);
    setPdf(characterpdf);
    setButtonShow(false);

    try {
      const result = await axios.get(`${Base_Url}/chat/getChat/${chatRoomId}`);
      console.log("result>>>result>>>>>", result);
      if (result?.data?.userChat?.messages) {
        setMessages(result?.data?.userChat?.messages);
      }
    } catch (error) {
      console.log("error>>>>>", error);
    }

    window.location.hash = chatRoomId;
  };
  console.log("charactename>>>>>>>", charctername);

  //function for send message
  const handleSendClick = async () => {
    if (inputMessage) {
      // setShowResponse(false)
      setShowResponse((prevLoading) => ({
        ...prevLoading,
        [characterId]: true,
      }));
      const userMessage = {
        type: "user",
        message: inputMessage,
        audio: "",
      };
      console.log("userMessage>>>", userMessage);
      setInputMessage("");
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const msgOuterDiv = document.getElementById("msg_outer");
      const lastDiv = msgOuterDiv?.lastElementChild;
      console.log("divetofocus", lastDiv);
      lastDiv?.focus();
      lastDiv?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
        scrollOffset: "50px",
      });

      try {
        let object;
        if (!chatroomId && !introMessage && !characterNameSpace) {
          object = {
            messageContent: inputMessage,
            characterId: characterId,
            UserId: UserId,
            prompt: prompt,
          };
        } else if (!chatroomId && !characterNameSpace) {
          object = {
            messageContent: inputMessage,
            characterId: characterId,
            UserId: UserId,
            prompt: prompt,
            introMessage: introMessage,
          };
        } else if (!chatroomId) {
          object = {
            messageContent: inputMessage,
            characterId: characterId,
            UserId: UserId,
            prompt: prompt,
            introMessage: introMessage,
            characterNameSpace: characterNameSpace,
          };
        } else if (!characterNameSpace) {
          object = {
            messageContent: inputMessage,
            characterId: characterId,
            UserId: UserId,
            prompt: prompt,
            introMessage: introMessage,
            chatRoomId: chatroomId,
          };
        } else {
          object = {
            messageContent: inputMessage,
            UserId: UserId,
            chatRoomId: chatroomId,
            characterId: characterId,
            prompt: prompt,
            introMessage: introMessage,
            characterNameSpace: characterNameSpace,
          };
        }
        const response = await axios.post(`${Base_Url}/chat/create`, object);
        console.log("apiresponse>>>>", response);
        setChatRoomId(response.data.chatRoomId);

        const ai_response = response.data.message;
        const audioBuffer = response.data.audioUrl;
        console.log("audioBuffer>>>>>>>>", audioBuffer);
        if (ai_response) {
          dispatch(actionCreators.fetchUserPoints(UserId));
          // setShowResponse(true)
          setShowResponse((prevLoading) => ({
            ...prevLoading,
            [characterId]: false,
          }));
          const botMessage = {
            type: "bot",
            message: ai_response,
            audioUrl: audioBuffer,
          };
          setMessages((prevMessages) => [...prevMessages, botMessage]);
        }
        console.log("message>>>>>", messages);

        if (response.data.chatRoomId) {
          const response = await axios.get(
            `${Base_Url}/chat/chatrooms/${UserId}`
          );
          console.log("roomresponse>>>>", response);
          setChatRooms(response?.data?.combinedData);
        }
      } catch (error) {
        console.error("API request error:", error);
        // setShowResponse(true)
        setShowResponse((prevLoading) => ({
          ...prevLoading,
          [characterId]: false,
        }));
        if (
          error.response.data.error == "Insufficient points to send a message"
        ) {
          setOpen(true);
          // toast.error(error.response.data.error)
        } else {
          toast.error(error.response.data.message);
        }
      }
    }
    const msgOuterDiv = document.getElementById("msg_outer");
    const lastDiv = msgOuterDiv?.lastElementChild;
    console.log("divetofocus", lastDiv);
    lastDiv?.focus();
    lastDiv?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
      scrollOffset: "50px",
    });
  };

  useEffect(() => {
    const msgOuterDiv = document.getElementById("msg_outer");
    const lastDiv = msgOuterDiv?.lastElementChild;
    console.log("divetofocus", lastDiv);
    lastDiv?.focus();
    lastDiv?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
      scrollOffset: "50px",
    });
  }, [lengthofdiv]);

  useEffect(() => {
    const chatLog = document.getElementById("msg_outer");
    const lengthOfDiv = chatLog.children.length - 1;
    if (lengthOfDiv > 0) {
      const lastMsgDiv = chatLog.children[lengthOfDiv - 1];
      setLenghtofDiv(lastMsgDiv.innerText);
    }
  }, [messages]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  //function for start recording
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioStream.current = stream;
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorderRef.current = mediaRecorder;

    const chunks = [];

    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.push(e.data);
      }
    };
    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(chunks, { type: "audio/mp3" });
      console.log("audioBlob>>>>", audioBlob);
      setAudioData(audioBlob);
      sendAudioToBackend(audioBlob);
      setAudioDataArray((prevAud) => [...prevAud, audioBlob]);
    };
    mediaRecorder.start();
    setRecording(true);
    setHideButton(true);
  };

  //function for stop recording
  const stopRecording = () => {
    if (audioStream.current) {
      audioStream.current.getTracks().forEach((track) => {
        track.stop();
      });
    }

    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setRecording(false);
    setHideButton(true);
  };

  //function for delete audio
  const deleteAudio = () => {
    setAudioData(null);
    setHideButton(false);
  };

  //function for send audio backend
  const sendAudioToBackend = async (audioData) => {
    if (audioData) {
      setShowResponse(false);
      const formData = new FormData();
      formData.append("audio", audioData);
      console.log("audioData", audioData);
      try {
        const response = await axios.post(
          `${Base_Url}/chat/transcribe`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("response>>>>>", response);
        if (response.status === 200) {
          const data = response.data;
          console.log("Transcription:>>>>>>", data.transcription);
          setHideButton(false);
          // Create a user message for the audio
          const audioUserMessage = {
            type: "user",
            message: data.transcription,
            response: "",
          };
          setInputMessage(audioUserMessage.message);
        } else {
          console.error("Error in response:", response.statusText);
          toast.error("Error transcribing audio data");
        }
      } catch (error) {
        console.error("Error sending audio data:", error);
        toast.error("Error transcribing audio data");
      }
    }
    const msgOuterDiv = document.getElementById("msg_outer");
    const lastDiv = msgOuterDiv?.lastElementChild;
    console.log("divetofocus", lastDiv);
    lastDiv.focus();
    lastDiv.scrollIntoView({
      behavior: "smooth",
      block: "start",
      scrollOffset: 50,
    });
  };

  //function for get userchat
  useEffect(() => {
    const getUserChat = async () => {
      try {
        const result = await axios.get(
          `${Base_Url}/chat/getChat/${chatroomId}`
        );
        console.log("historyresult>>>>>>", result);
        if (result.data?.userChat?.messages) {
          setMessages(result?.data?.userChat?.messages);
        }
      } catch (error) {
        console.log("error>>>>>", error);
      }
    };
    getUserChat();
  }, [chatroomId, Base_Url]);

  //open delete popup
  const handleOpenDeletePopup = () => {
    setOpenDeletePopUp(true);
  };

  //getcharacters
  // useEffect(() => {
  //   const getcharacters = async() => {
  //     try{
  //     const result =await axios.get(`${Base_Url}/character/get_character`);
  //     console.log("result>>>>>>>",result)
  //     setCharacters(result?.data?.characters);
  //     }catch(error){
  //       console.log("error>>>>>",error)
  //     }
  //   }
  //   getcharacters();
  // }, [])

  //getGroupcharacters
  // useEffect(() => {
  //   const getGroupcharacters = async() => {
  //     try{
  //     const result = await axios.get(`${Base_Url}/character/get_group`);
  //     console.log("Groupresult>>>>>>>",result)
  //     setGroupCharacters(result?.data?.groupDetail)
  //     }catch(error){
  //       console.log("error>>>>>",error)
  //     }
  //   }
  //   getGroupcharacters();
  // }, [])

  //edit character or group charcter function

  const handleEdit = () => {
    const formdata = {
      characterImage: image,
      characterName: name,
      characterDescription: description,
      characterPrompt: prompt,
      charcterIntro: introMessage,
      characterCategory: location?.state?.data?.category,
      characterVoice: location?.state?.data?.voice,
      characterVisibility: location?.state?.data?.visibility,
      characterNameSpace: characterNameSpace,
      characterUrl: characterUrl,
      pdf: pdf,
    };
    navigate("/create-character", {
      state: { id: characterId, characterData: formdata },
    });
  };

  const handleGroupEdit = () => {
    const formdata = {
      groupImage: groupImage,
      groupName: groupname,
      groupDescription: groupDescription,
      groupCharcter: groupCharcter,
      groupMember: groupmember,
      groupTopic: groupTopic,
    };
    navigate("/group-character", {
      state: { id: groupId, groupData: formdata },
    });
  };

  useEffect(() => {
    const getChatRooms = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}/chat/chatrooms/${UserId}`
        );
        console.log("roomresponse>>>>", response);
        setChatRooms(response?.data?.combinedData);

        if (response?.data?.combinedData) {
          if (window.location.hash) {
            const matchingEntry = response?.data?.combinedData.find(
              (entry) => entry?.chatRoomId === window.location.hash.slice(1)
            );
            console.log("matchingEntry>>>", matchingEntry);
            setChatRoomId(window.location.hash.slice(1));
            setCharacterId(matchingEntry?.characterId);
            setName(matchingEntry?.chracterName);
            setImage(matchingEntry?.chracterimage);
            setDescription(matchingEntry?.chracterDescription);
            setPrompt(matchingEntry?.prompt);
            setCharacterNameSpace(matchingEntry?.characterNameSpace);
            setCharacterUrl(matchingEntry.characterUrl);
            setPdf(matchingEntry.characterpdf);
          } else if (location?.state?.data) {
            setCharacterId(location?.state?.data?._id);
            setName(location?.state?.data?.name);
            setImage(location?.state?.data?.image);
            setDescription(location?.state?.data?.description);
            setPrompt(location?.state?.data?.prompt);
            const matchingEntry = response?.data?.combinedData.find(
              (entry) => entry?.characterId === location?.state?.data?._id
            );
            console.log("matchingEntry>>>>>>>>>", matchingEntry);
            setChatRoomId(matchingEntry?.chatRoomId);
            setCharacterNameSpace(location?.state?.data?.characterNameSpace);
            setCharacterUrl(location?.state?.data?.characterUrl);
            setPdf(location?.state?.data?.pdf);
          } else if (location?.state?.groupdata) {
            setCharacterId(location?.state?.data?._id);
            setName(location?.state?.data?.name);
            setImage(location?.state?.data?.image);
            setDescription(location?.state?.data?.description);
            setPrompt(location?.state?.data?.prompt);
            const matchingEntry = response?.data?.combinedData.find(
              (entry) => entry?.characterId === location?.state?.groupdata?._id
            );
            console.log("matchingEntry>>>>>>>>>", matchingEntry);
            setChatRoomId(matchingEntry?.chatRoomId);
            setCharacterNameSpace(location?.state?.data?.characterNameSpace);
            setCharacterUrl(location?.state?.data?.characterUrl);
            setPdf(location?.state?.data?.pdf);
          } else {
            setChatRoomId(response?.data?.combinedData?.[0]?.chatRoomId);
            setCharacterId(response?.data?.combinedData?.[0]?.characterId);
            setName(response?.data?.combinedData?.[0]?.chracterName);
            setImage(response?.data?.combinedData?.[0]?.chracterimage);
            setDescription(
              response?.data?.combinedData?.[0]?.chracterDescription
            );
            setPrompt(response?.data?.combinedData?.[0]?.prompt);
            setCharacterNameSpace(
              response?.data?.combinedData?.[0]?.characterNameSpace
            );
            setCharacterUrl(response?.data?.combinedData?.[0]?.characterUrl);
            setPdf(response?.data?.combinedData?.[0]?.characterpdf);
          }
        }
      } catch (error) {
        console.log("error>>>>>", error);
      }
    };
    getChatRooms();
  }, [Base_Url, UserId, location?.state?.data]);

  const handleClearMemory = async () => {
    console.log("UserId>>>>", UserId);
    try {
      const result = await axios.delete(
        `${Base_Url}/chat/deleteChatRoom/${chatroomId}?UserId=${UserId}`
      );
      console.log("result>>>>", result);
      if (result) {
        window.location.reload();
        setButtonShow(false);
      }
    } catch (error) {
      console.log("error>>>>", error);
    }
  };

  const handleToggleButton = (chatRoomId) => {
    if (buttonShow === chatRoomId) {
      setButtonShow(false); // Hide the button if it's already shown
    } else {
      setButtonShow(chatRoomId); // Show the button for the selected item
    }
  };

  return (
    <>
      <HeaderPopup open={open} setOpen={setOpen} isChatScreen />
      <ToastContainer />
      <DeletePopup
        setOpenDeletePopUp={setOpenDeletePopUp}
        openDeletePopUp={openDeletePopUp}
        chatroomId={chatroomId}
        setClearMemory={setClearMemory}
      />
      <div className="dashboard-main inner-screen">
        <div className="search_content">
          <div className="pinned_chat">
            <div className="heading">{/* <h3>チャット</h3> */}</div>
            <div className="bot_character">
              <ul>
                {chatRooms &&
                  chatRooms?.map((value, index) => {
                    return (
                      <li key={index}>
                        <div
                          className={`label_box ${
                            value.characterId === characterId ? "active" : ""
                          }`}
                        >
                          <div
                            className="chatroom_image"
                            onClick={() =>
                              handleChatRoom(
                                value.chracterName,
                                value.chracterimage,
                                value.chracterDescription,
                                value.prompt,
                                value.chatRoomId,
                                value.characterId,
                                value.characterNameSpace,
                                value.characterUrl,
                                value.characterpdf
                              )
                            }
                          >
                            <img
                              src={value.chracterimage}
                              alt="character_image"
                            />
                            {value.chracterName}
                          </div>
                          <svg
                            onClick={() => handleToggleButton(value.chatRoomId)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                              stroke="#A0A0A0"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
                              stroke="#A0A0A0"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
                              stroke="#A0A0A0"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                        {buttonShow === value.chatRoomId && (
                          <div className="delete_button">
                            <button type="submit" onClick={handleClearMemory}>
                              会話履歴を削除する
                            </button>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>

        <div className="chat_box">
          <div className="chat_box_inner">
            <div className="chat-container">
              <div className="character_des">
                <div className="edit-card">
                  <div className="image-card">
                    {isGroupCharcter ? (
                      <img className="heading_img" src={groupImage} alt="" />
                    ) : (
                      <img className="heading_img" src={image} alt="" />
                    )}
                    <div className="image-text">
                      <h2>{isGroupCharcter ? groupname : name}</h2>
                      <p>{isGroupCharcter ? groupDescription : description}</p>
                    </div>
                  </div>
                  <div className="btnn">
                    <button className="btn-style" type="button">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M4 12V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V12"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 6L12 2L8 6"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 2V15"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      共有
                    </button>
                    <button
                      className="btn-style"
                      type="button"
                      onClick={isGroupCharcter ? handleGroupEdit : handleEdit}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      編集
                    </button>
                  </div>
                </div>
                {/* {member?.flat()?.map((value)=><li className="list-text">{value}</li>)} */}
              </div>

              <div className="chat-messages" id="msg_outer">
                {messages.map((message, index) => {
                  return (message.type || message.sender) === "user" ? (
                    <div className="user" key={index}>
                      {/* Include your audio handling logic here */}
                      <div className="user_message">
                        {/* {(message.type === 'audio') && 
                         <audio controls src={URL?.createObjectURL(audioDataArray)}></audio>
                        } */}
                        <p> {message.message || message.content} </p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="ai-response_name">
                        <p>
                          <span>
                            <img src={image} alt="" />
                          </span>
                          {name}
                        </p>
                      </div>
                      <div className="ai-response" key={index}>
                        {message.audioUrl && (
                          <>
                            <audio controls>
                              <source
                                src={
                                  Base_Url +
                                  `/chat/${message.audioUrl?.split("/")?.pop()}`
                                }
                                type="audio/mp3"
                              />
                            </audio>
                          </>
                        )}
                        <ReactMarkdown>
                          {message.message || message.content}
                        </ReactMarkdown>
                        {/* <p>{message.message || message.content}</p> */}
                      </div>
                    </>
                  );
                })}
                {console.log("mes>>>>>>>", messages)}
                {messages && messages.length === 0 && introMessage ? (
                  <>
                    <div className="ai-response_name">
                      <p>
                        <span>
                          <img src={image} alt="" />
                        </span>
                        {name}
                      </p>
                    </div>
                    <div className="ai-response">
                      <p>{introMessage}</p>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="random_spinner">
                  {/* {!showResponse && <div className="spinner" > */}
                  {showResponse[characterId] && (
                    <div className="spinner">
                      <div className="bounce1"></div>
                      <div className="bounce2"></div>
                      <div className="bounce3"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="msg_type_outer">
              <button
                type="submit"
                className="filter_btn"
                onClick={handleOpenDeletePopup}
                disabled={messages.length === 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 6H5H21"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10 11V17"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 11V17"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <input
                type="text"
                placeholder={
                  showResponse
                    ? "メッセージを入力してください..."
                    : "回答待ち..."
                }
                className="form-control"
                value={inputMessage}
                onChange={handleinputChange}
                onKeyPress={handleKeyPress}
              />
              {inputMessage && (
                <button
                  className="filter_btn"
                  type="button"
                  disabled={!inputMessage}
                  onClick={handleSendClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    class="fill-[#4b5563] w-[24px] h-[24px]"
                    focusable="false"
                  >
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"></path>
                  </svg>{" "}
                </button>
              )}
              <div className="recording_button">
                {recording ? (
                  <button className="filter_btn" onClick={stopRecording}>
                    <span className="stop_record"></span>stop
                  </button>
                ) : !inputMessage ? (
                  <button className="filter_btn" onClick={startRecording}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      aria-hidden="true"
                      className="w-[36px] h-[36px] fill-[#ff0000]"
                    >
                      <path d="M8.25 4.5a3.75 3.75 0 117.5 0v8.25a3.75 3.75 0 11-7.5 0V4.5z"></path>
                      <path d="M6 10.5a.75.75 0 01.75.75v1.5a5.25 5.25 0 1010.5 0v-1.5a.75.75 0 011.5 0v1.5a6.751 6.751 0 01-6 6.709v2.291h3a.75.75 0 010 1.5h-7.5a.75.75 0 010-1.5h3v-2.291a6.751 6.751 0 01-6-6.709v-1.5A.75.75 0 016 10.5z"></path>
                    </svg>
                  </button>
                ) : (
                  ""
                )}
                {/* {hideButton && audioData ? (
                  <div>
                    <button type="delete" onClick={deleteAudio}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M3 6H5H21"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 11V17"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14 11V17"
                          stroke="black"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                    <audio
                      controls
                      src={URL?.createObjectURL(audioData)}
                    ></audio>
                    <button onClick={sendAudioToBackend}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                      >
                        <path
                          d="M9.6651 13.5802L12.7666 19.0079C13.0435 19.4924 13.1819 19.7346 13.1819 20.0002C13.1819 20.2657 13.0435 20.508 12.7666 20.9924L9.6651 26.4201C8.4268 28.5872 7.80765 29.6707 8.29047 30.208C8.7733 30.7453 9.91661 30.2451 12.2032 29.2447L29.1455 21.8325C30.941 21.0469 31.8388 20.6542 31.8388 20.0002C31.8388 19.3461 30.941 18.9534 29.1455 18.1678L12.2032 10.7556C9.91661 9.7552 8.7733 9.255 8.29047 9.79232C7.80765 10.3296 8.4268 11.4132 9.6651 13.5802Z"
                          fill="#012AFF"
                        />
                      </svg>
                    </button>
                  </div>
                ) : (
                  ""
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatScreen;
