import React, { useEffect } from "react";
import axios from "axios";

import { PATREON_CHECKOUT_LINKS } from "./patreonConstants";

const PatreonCheckout = (props) => {
  const Base_Url = process.env.REACT_APP_URL;
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const handleRedirect = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      console.log("code>>>>>>>>", code);
      if (!code)
        window.location.href = process.env.REACT_APP_FRONTEND_URL + "/home";

      const checkoutOption = params.get("state");
      console.log("checkoutOption>>>>>", checkoutOption);
      if (!checkoutOption)
        window.location.href = process.env.REACT_APP_FRONTEND_URL + "/home";

      try {
        const response = await axios.post(
          `${Base_Url}/payment/paytreon-payment`,
          {
            code: code,
            userId: userId,
          }
        );
        const accessToken = response.data.access_token;
        const patreonAuthUrl = PATREON_CHECKOUT_LINKS[checkoutOption];
        console.log("Access Token:", accessToken);
        window.location.href = patreonAuthUrl;
      } catch (error) {
        console.error("Error exchanging code for access token:", error);
      }
    };

    handleRedirect();
  }, [Base_Url, userId]);

  return (
    <>
      <h1 style={{ marginLeft: "40%", marginTop: "20%" }}>Redirecting...</h1>
    </>
  );
};

export default PatreonCheckout;
