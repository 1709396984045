import {useState} from 'react'
import axios from 'axios';
import {useNavigate } from 'react-router-dom';
import '../login-signup/login.css';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const UpdatePassword = ({setIsAuthenticated}) => {

  const Base_Url = process.env.REACT_APP_URL;

    const navigate = useNavigate()
    const [input, setInput] = useState({
        email: "",
        password: ""
    })
    
    const [errors, setErrors] = useState({ email: '', password: '' });

    const handleChange = (e) =>{
        const name = e.target.name;
        const value = e.target.value;
    
        setInput((values) => ({ ...values, [name]: value }))
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    
    const handleUpdatePassword = async(e) =>{
        e.preventDefault();

        let valid = true;

        if (!input.email) {
          setErrors((prevErrors) => ({ ...prevErrors, email: 'メールアドレスは必須です*' }));
          valid = false;
        }
        else if (!input.password) {
          setErrors((prevErrors) => ({ ...prevErrors, password: 'パスワードが必要*' }));
          valid = false;
        }
       else{   
        const object = {
            email:input.email,
            newPassword:input.password
        }
        try {
            const result = await axios.post("/users/reset-password",object)
            console.log(result)
            if(result.data.success===true){
              setIsAuthenticated(true)
              localStorage.setItem('isAuthenticated', 'true');
                navigate('/')
            }
        } catch (error) {
            console.log("error>>>",error)
            toast.error(error.response.data.error)
        }
      }
    }
    

  return (
    <>
    <ToastContainer/>
      <div className='login-body'>
        <div className='login-cards'>
      <form>
        <div className='heading'>
            <h3>パスワードを更新する</h3>
        </div>
      <div className="form-group">
        <input type="email" className="form-control" name="email" placeholder="メールアドレスを入力"
        value={input.email}
        onChange={handleChange}
        />
        <div className="error-message">{errors.email}</div>
      </div>
     <div className="form-group">
        <input type="password" className="form-control" name="password"  placeholder="新しいパスワードを入力してください"
        value={input.password}
        onChange={handleChange}
        />
        <div className="error-message">{errors.password}</div>
      </div>
     <button type="submit" className="btn btn-primary" onClick={handleUpdatePassword}>保存</button>
</form>
</div>
</div>
    </>
  )
}

export default UpdatePassword;
