import React,{useState,useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";

const PaymentCallback = (props) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()


    useEffect(() => {
        let token = searchParams.get("payment_token")
        call_paymentAPI(token).then(r => window.location.href = "/home").catch(e => {
            window.location.href = "/home"
        });
    }, []);

    const call_paymentAPI = async (paymentToken) => {
        const Base_Url = process.env.REACT_APP_URL;
        try {
            const result = await axios.post(`${Base_Url}/payment/slash-success`,
                { paymentToken });
            console.log("result>>>>>>>", result)
            return result.data
        } catch (error) {
            console.log("error>>>>>", error)
            throw "Error"
        }
    }

    return(
        <div>
            <h1>Payment Successful</h1>
        </div>
    )
}

export default PaymentCallback;
