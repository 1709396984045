import {useState} from 'react';
import axios from 'axios';
import { Link,useNavigate } from 'react-router-dom';
// import {GoogleLogin} from 'react-google-login';
import '../login-signup/login.css';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const Client_Secret =process.env.REACT_APP_GOOGLE_CLIENT_SECRET;

const Signup = ({setIsAuthenticated}) => {

  const Base_Url = process.env.REACT_APP_URL;
  console.log('BaseUrl>>>>>>>>>>>',Base_Url)
const Discord_client_id =process.env.REACT_APP_DISCORD_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;

console.log("ids>>>>",clientId,Discord_client_id)

const navigate = useNavigate()

const [input, setInput] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: ""
})

// <================state for showing error messages==================================>
const [errorMessages, setErrorMessages] = useState({
	firstname: "",
	lastname:"",
  email: "",
  password: "",
   })




const handleChange = (e) =>{
    const name = e.target.name;
    const value = e.target.value;

    setInput((values) => ({ ...values, [name]: value }))
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: '' }));
}

const handleSignUp = async(e) =>{
    e.preventDefault(); 
    

    let valid = true;
    if (!input.firstname) {
      setErrorMessages((prevErrors) => ({ ...prevErrors, firstname: '名は必須です*' }));
      valid = false;
    }
    else if (!input.lastname) {
      setErrorMessages((prevErrors) => ({ ...prevErrors, lastname: '姓は必須です*' }));
      valid = false;
    }
    else if (!input.email) {
      setErrorMessages((prevErrors) => ({ ...prevErrors, email: 'メールアドレスは必須です*' }));
      valid = false;
    }
    else if (!input.password) {
      setErrorMessages((prevErrors) => ({ ...prevErrors, password: 'パスワードが必要*' }));
      valid = false;
    }

    else{
    
    const object = {
        firstname:input.firstname,
        lastname:input.lastname,
        email:input.email,
        password:input.password,
        points:100,
        subscription: false
    }
    try {
        const result = await axios.post(`${Base_Url}/users/signup`,object)
        console.log("result>>>>>",result)
        if(result.data.success===true){
          setIsAuthenticated(true)
          localStorage.setItem('isAuthenticated', true);
          const first_name = result?.data?.userData?.firstname
          const user_id = result?.data?.userData?._id
          const userEmail = result.data.userData.email
          const points = result.data.userData.points
           localStorage.setItem("username", (first_name))
				   localStorage.setItem("userId", (user_id))
           localStorage.setItem('email',userEmail)
           localStorage.setItem('points',points)
            navigate('/home')
        }
    } catch (error) {
        console.log("error>>>",error)
        const errorMessage = error?.response?.data?.error
        toast.error(errorMessage)
    }
  }
  
};


// const onSuccess = async (res) => { 
//   console.log("res>>>>>",res)
//   console.log("gdgffgj>>>>>>", res.profileObj)
//   console.log(">>>>>>>>>>>>>>", res.accessToken);
//   try {
//     let obj = {
//       firstname: res.profileObj.givenName,
//       lastname: res.profileObj.familyName,
//       email: res.profileObj.email,
//       type:"google",
//       password: res.profileObj.googleId,

//     }

//     const result = await axios.post("http://192.168.1.5:8081/users/signup", obj)
//     console.log("result>>>", result)
//     if(result.data.success===true){
//       const first_name = result.data.userData.firstname
//       const user_id = result.data.userData._id
//        localStorage.setItem("username", (first_name))
//         localStorage.setItem("userId", (user_id))
//         navigate('/home')
//     }
//   }
//   catch (error) {
//     console.log(error)
//     console.log("status>>>", error.response.status)
//     console.log("message>>>", error.response.data.message)

//     if (error.response.status === 400) {
//      console.log(error.response.data.message)
//      toast.error(error.response.data.message)
//     }
//   }
// }



// const onFailure = (res) => {
//   console.log("Login Failer!", res)
// }


const discordSignup = (e)=>{
  e.preventDefault()
  window.location.href = `https://discord.com/oauth2/authorize?client_id=${Discord_client_id}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=identify%20email`;
}


return (
    <>
<ToastContainer/>
<div className='login-body'>
        <div className='login-cards'>
<form>
<div className='heading'>
            <h3>登録ページ</h3>
        </div>

        {/* <GoogleLogin
										clientId={clientId}
										buttonText="Signup with Google"
										onSuccess={onSuccess}
										onFailure={onFailure}
										cookiePolicy={'single_host_origin'}
										prompt="select_account"
									/> */}
                  <br/>
      {/* <button type='submit' className='btn btn-primary' onClick={discordSignup}>Signup with Discord</button> */}
  {/* <p className='or_content'>OR</p> */}
  <div className="form-group">
    <input type="text" className="form-control" name='firstname'  placeholder="名前を入力"
    value={input.firstname}
    onChange={handleChange}
    />
    <div className="error-message">{errorMessages.firstname}</div>
  </div>
  <div className="form-group">
    <input type="text" className="form-control" name="lastname" placeholder="姓を入力"
     value={input.lastname}
     onChange={handleChange}
    />
    <div className="error-message">{errorMessages.lastname}</div>
  </div>
  <div className="form-group">
    <input type="email" className="form-control" name="email" placeholder="Eメールを入力"
    value={input.email}
    onChange={handleChange}
    />
    <div className="error-message">{errorMessages.email}</div>
  </div>
  <div className="form-group">
    <input type="password" className="form-control" name="password"  placeholder="パスワードを入力"
    value={input.password}
    onChange={handleChange}
    />
    <div className="error-message">{errorMessages.password}</div>
  </div>
  <button type="submit" className="btn btn-primary" onClick={handleSignUp}>会員登録</button>
  <p className='login-bottom'>すでにアカウントをお持ちの方 ?<Link to='/'>ログイン</Link></p>
</form>
</div> 
</div>      
    </>
  )
}

export default Signup
