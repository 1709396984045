import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

const Home = () => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log("BaseUrl>>>>>>>>>>>", Base_Url);

  const userId = localStorage.getItem("userId");

  const [character, setCharacter] = useState("");
  const [personalCharacter, setPersonalCharacter] = useState("");
  console.log("personalCharacter>>>>", personalCharacter);
  const [selectedCategory, setSelectedCategory] = useState(null);
  console.log("selectedCategory>>>", selectedCategory);
  const [groupCharacter, setGroupCharacters] = useState("");
  console.log("groupCharacter>>>>>>>>>", groupCharacter);

  const allCharacter = [...character, ...personalCharacter];
  console.log("allcharacters>>>>>>", allCharacter);

  const navigate = useNavigate("");

  // getcharacters
  useEffect(() => {
    const getcharacters = async () => {
      try {
        const result = await axios.get(
          `${Base_Url}/character/getMyCharacters/${userId}`
        );
        console.log("result>>>>>>>", result);
        setPersonalCharacter(result?.data?.characters);
      } catch (error) {
        console.log("error>>>>>", error);
      }
    };
    getcharacters();
  }, [Base_Url, userId]);

  useEffect(() => {
    const getPublicCharacters = async () => {
      try {
        const result = await axios.get(`${Base_Url}/character/get_character`);
        console.log("Publicresult>>>>>>>", result);
        setCharacter(result?.data?.characters);
      } catch (error) {
        console.log("error>>>>>", error);
      }
    };
    getPublicCharacters();
  }, [Base_Url, userId]);

  const handleNavigate = (
    _id,
    image,
    name,
    description,
    prompt,
    introMessage,
    userId,
    category,
    voice,
    visibility,
    characterNameSpace,
    url,
    pdf
  ) => {
    console.log("idss>>>>>>>", _id, image, name, description);

    const detail = {
      _id: _id,
      name: name,
      image: image,
      description: description,
      prompt: prompt,
      introMessage: introMessage,
      userId: userId,
      category: category,
      voice: voice,
      visibility: visibility,
      characterNameSpace: characterNameSpace,
      characterUrl: url,
      pdf: pdf,
    };

    navigate("/chat", { state: { data: detail } });
  };

  const filteredCharacters = selectedCategory
    ? selectedCategory === "New"
      ? personalCharacter.slice(-2)
      : personalCharacter.filter((value) => value.category === selectedCategory)
    : personalCharacter;

  console.log("filteredcharacter>>>>>>", filteredCharacters);

  useEffect(() => {
    const getGroupcharacters = async () => {
      try {
        const groupResponse = await axios.get(
          `${Base_Url}/character/get_group/${userId}`
        );
        console.log("groupResponse>>>>>>>>>>>>", groupResponse);
        setGroupCharacters(groupResponse?.data?.groupDetail);
      } catch (error) {
        console.log("error>>>>>>>>", error);
      }
    };
    getGroupcharacters();
  }, [Base_Url]);

  const handleChatNavigate = (
    _id,
    image,
    name,
    description,
    members,
    botcharacters,
    topic
  ) => {
    const detail = {
      _id: _id,
      name: name,
      image: image,
      description: description,
      members: members,
      botcharacters: botcharacters,
      userId: userId,
      isGroup: true,
      topic: topic,
    };

    navigate("/chat", { state: { groupdata: detail } });
  };

  return (
    <>
      <div className="home_page inner-screen">
        <div className="chatpage_character">
          <div className="heading">
            <h3>
              {selectedCategory ? selectedCategory : "おすすめキャラクター"}
            </h3>
          </div>

          <div className="bot_character_des">
            <div className="character-card">
              <Swiper
                slidesPerView={4}
                spaceBetween={30}
                loop={true}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {filteredCharacters && filteredCharacters.length > 0 ? (
                  filteredCharacters?.map((value, index) => {
                    const isHighlighted =
                      value.category === selectedCategory ||
                      selectedCategory === "New";
                    console.log("ishiglighted>>>>>>", isHighlighted);

                    return (
                      <SwiperSlide
                        key={value._id}
                        className={`character ${
                          isHighlighted ? "highlighted" : ""
                        }`}
                      >
                        <div
                          onClick={() =>
                            handleNavigate(
                              value._id,
                              value.image,
                              value.name,
                              value.description,
                              value.prompt,
                              value.introMessage,
                              value.userId,
                              value.category,
                              value.voice,
                              value.visibility,
                              value.characterNameSpace,
                              value.url,
                              value.pdf
                            )
                          }
                        >
                          <img src={value.image} alt="" />
                          <h3>{value.name}</h3>
                          <p>{value.description}</p>
                        </div>
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <p className="no-character">
                    選択したカテゴリに文字が見つかりませんでした
                  </p>
                )}
              </Swiper>
            </div>

            {/* <div className='character-card'>
              {groupCharacter && groupCharacter?.map((value,index)=>{
                console.log("value>>>>>",value)
                return(
                  <div className='character' >
                    <img src={`${Base_Url}/character/${value.group.image}`} alt="" />
                    <h3>{value.group.name}</h3>
                    <p>{value.group.description}</p>
                  </div>       
                )
              })}
             </div> */}

            <div className="bot_reference_list">
              <ul>
                <li onClick={() => setSelectedCategory("おすすめ")}>
                  おすすめ
                </li>
                <li onClick={() => setSelectedCategory("New")}>New</li>
                <li onClick={() => setSelectedCategory("アシスタント")}>
                  アシスタント
                </li>
                <li onClick={() => setSelectedCategory("学習")}>学習</li>
                <li onClick={() => setSelectedCategory("ゲーム")}>ゲーム</li>
                <li onClick={() => setSelectedCategory("アニメ")}>アニメ</li>
              </ul>
            </div>
            <div className="bot_reference_list">
              <ul>
                <li onClick={() => setSelectedCategory("ディスカッション")}>
                  ディスカッション
                </li>
                <li onClick={() => setSelectedCategory("宗教")}>宗教</li>
                <li onClick={() => setSelectedCategory("ストーリー")}>
                  ストーリー
                </li>
                <li onClick={() => setSelectedCategory("コメディ")}>
                  コメディ
                </li>
                <li onClick={() => setSelectedCategory("歴史")}>歴史</li>
                <li onClick={() => setSelectedCategory("その他")}>その他</li>
              </ul>
            </div>

            <br />
            {groupCharacter && groupCharacter.length >= 0 && (
              <>
                <div className="heading">
                  <h3>おすすめグループキャラ</h3>
                </div>
                <div className="character-card">
                  <Swiper
                    slidesPerView={4}
                    spaceBetween={30}
                    loop={true}
                    freeMode={true}
                    pagination={{
                      clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                  >
                    {groupCharacter?.map((value, index) => {
                      console.log("val;ue>>>>", value);
                      return (
                        <SwiperSlide
                          key={value.group._id}
                          className="character"
                        >
                          <div
                            onClick={() =>
                              handleChatNavigate(
                                value.group._id,
                                value.group.image,
                                value.group.name,
                                value.group.description,
                                value.group.members,
                                value.botCharacters,
                                value.group.topic
                              )
                            }
                          >
                            <img src={value.group.image} alt="" />
                            <h3>{value.group.name}</h3>
                            <p>{value.group.description}</p>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </>
            )}
          </div>

          <div className="character_list">
            {personalCharacter &&
              personalCharacter.map((value, index) => {
                const isHighlighted = value.category === selectedCategory;
                return (
                  <div
                    className={`list_inner ${
                      isHighlighted ? "highlighted" : ""
                    }`}
                    onClick={() =>
                      handleNavigate(
                        value._id,
                        value.image,
                        value.name,
                        value.description,
                        value.prompt,
                        value.introMessage,
                        value.userId,
                        value.category,
                        value.voice,
                        value.visibility,
                        value.characterNameSpace,
                        value.url,
                        value.pdf
                      )
                    }
                  >
                    <img src={value.image} alt="" />
                    <div className="right-t">
                      <h4>{value.name}</h4>
                      <p>{value.description}</p>
                    </div>
                  </div>
                );
              })}

            {/* {personalCharacter && personalCharacter.map((value, index) => {
              return (
                <div className="list_inner" onClick={() => handleNavigate(value._id, value.image, value.name, value.description, value.prompt)}>
                  <img src={`${Base_Url}/character/${value.image}`} alt="" />
                  <div className='right-t'>
                    <h4>{value.name}</h4>
                    <p>{value.description}</p>
                  </div>
                </div>
              )
            })} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
