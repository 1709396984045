import React from "react";
import axios from "axios";

const PricingCard = ({ amount, points, isPatreon }) => {
  const call_paymentAPI = async (e) => {
    e.preventDefault();
    if (isPatreon) {
      handlePatreonPaymentLink();
    } else {
      handleCryptoPaymentLink();
    }
  };

  const handleCryptoPaymentLink = async () => {
    const Base_Url = process.env.REACT_APP_URL;
    const userId = localStorage.getItem("userId");
    // let testAmount = 1;
    try {
      const result = await axios.post(`${Base_Url}/payment/slash-payment`, {
        userId: userId,
        amount: amount,
      });
      console.log("result>>>>>>>", result);
      const hosted_url = result.data.url;
      window.location.href = hosted_url;
    } catch (error) {
      console.log("error>>>>>", error);
    }
  };

  const handlePatreonPaymentLink = async () => {
    const clientId =
      "FHPEek2F1meiSictkGrPByL34-RK5VnJGqt1k0vYE4fiIBn5NucAkxokzsOy43sV";
    // const redirect_Uri = 'http://24.144.82.176:3000/patreoncheckout';
    // const redirect_Uri = process.env.FRONTEND_URL + "/patreoncheckout";
    const redirect_Uri =
      process.env.REACT_APP_FRONTEND_URL + "/patreoncheckout";
    console.log("redirect uri", redirect_Uri);
    const patreonAuthUrl = `https://www.patreon.com/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_Uri}&state=${amount}`;

    window.location.href = patreonAuthUrl;
  };

  return (
    <div className="card">
      <div className="points">{points}ポイント</div>
      <div className="time">{amount}ドル</div>
      <button className="redeem-button" onClick={call_paymentAPI}>
        購入する
      </button>
    </div>
  );
};

export default PricingCard;
