import "./App.css";
import { useState, useEffect } from "react";
import ChatScreen from "./components/home/ChatScreen";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Signup from "./components/login-signup/Signup";
import Login from "./components/login-signup/Login";
import Header from "./components/sidebar/Header";
import UpdatePassword from "./components/login-signup/UpdatePassword";
import { gapi } from "gapi-script";
import Callback from "./components/login-signup/Callback";
import Sidebar from "./components/sidebar/Sidebar";
import CreateCharacter from "./components/home/CreateCharacter";
import GroupCharacter from "./components/home/GroupCharacter";
import PatreonCheckout from "./components/sidebar/PatreonCheckout";
import Home from "./components/home/Home";
import PaymentCallback from "./components/home/PaymentCallback";
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {
  const location = useLocation();
  console.log("location>>>>", location);

  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isAuthenticated") === "true"
  );

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  });

  return (
    <div className="App">
      {location.pathname !== "/" &&
        location.pathname !== "/signup" &&
        location.pathname !== "/update-password" && <Header />}
      <div className="all_content">
        {location.pathname !== "/" &&
          location.pathname !== "/signup" &&
          location.pathname !== "/update-password" && <Sidebar />}

        <Routes>
          <Route path="/auth/callback" element={<Callback />} />
          <Route
            path="/signup"
            element={<Signup setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route
            path="/home"
            element={isAuthenticated ? <Home /> : <Navigate to="/" />}
          />
          <Route
            path="/patreoncheckout"
            element={
              isAuthenticated ? <PatreonCheckout /> : <Navigate to="/" />
            }
          />
          <Route
            path="/chat"
            element={isAuthenticated ? <ChatScreen /> : <Navigate to="/" />}
          />
          <Route
            path="/create-character"
            element={
              isAuthenticated ? <CreateCharacter /> : <Navigate to="/" />
            }
          />
          <Route
            path="/group-character"
            element={isAuthenticated ? <GroupCharacter /> : <Navigate to="/" />}
          />
          <Route
            path="/update-password"
            element={<UpdatePassword setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route path="/payment-callback" element={<PaymentCallback />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
