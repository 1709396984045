import React from "react";
import Modal from "react-modal";
import "./pricingtable.css";
import PricingCard from "./PriceCard";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.8)", // This sets the opacity of the background
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#e5e5e5",
    borderRadius: "30px",
    overflow: "hidden",
  },
};

const patreonMessage = (
  <>
    <h3>クレジットカードでポイントを購入します。</h3>

    <p>
      {
        "(Patreonが起動します。決済を進めるには次の画面で「Allow」を選択してください。)"
      }
    </p>
  </>
);

const cryptoMessage = (
  <>
    <h3>暗号資産でポイントを購入します。</h3>

    <p>{"(Slash Paymentが起動します。ウォレットの接続が必要です。)"}</p>
  </>
);

const PricingTable = ({ open, setOpen, isPatreon }) => {
  const userId = localStorage.getItem("userId");

  console.log("userId>>", userId);
  let price_list = [
    {
      price: 30,
      points: 300,
    },
    {
      price: 50,
      points: 600,
    },
    {
      price: 100,
      points: 1500,
    },
    {
      price: 200,
      points: 4000,
    },
    {
      price: 300,
      points: 10000,
    },
  ];

  //close popup function
  const hanldeClosePopup = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={hanldeClosePopup}
        style={customStyles}
      >
        <div className="popup_layout">
          <div className="closePopup" onClick={hanldeClosePopup}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
            >
              <path
                d="M28.125 16.875L16.875 28.125"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.875 16.875L28.125 28.125"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="pricing-table_heading">
            {isPatreon ? patreonMessage : cryptoMessage}
          </div>
          <div className="pricing-table_container">
            <div className="pricing-table">
              {price_list.map((price_card) => (
                <PricingCard
                  amount={price_card.price}
                  points={price_card.points}
                  isPatreon={isPatreon}
                />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PricingTable;
