import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";

const GroupCharacter = () => {
  const Base_Url = process.env.REACT_APP_URL;
  console.log("BaseUrl>>>>>>>>>>>", Base_Url);

  const userId = localStorage.getItem("userId");
  const navigate = useNavigate("");

  const [imagePreview, setImagePreview] = useState("");
  console.log("imgprev>>>>>>>", imagePreview);
  const [imgSrc, setImgSrc] = useState("");
  const [members, setMembers] = useState([]);
  console.log("members>>>>>>", members);
  const [input, setInput] = useState({
    group_name: "",
    topic: "",
    description: "",
  });
  const [groupId, setGroupId] = useState("");

  const [userCharacter, setUserCharacter] = useState([]);
  console.log("userCharacter>>>>>>", userCharacter);
  const ImageRef = useRef("");
  const location = useLocation("");
  console.log("location>>>>>>>>>>", location);

  useEffect(() => {
    if (location?.state?.groupData) {
      setInput({
        group_name: location?.state?.groupData?.groupName,
        topic: location?.state?.groupData?.groupTopic,
        description: location?.state?.groupData?.groupDescription,
      });
      setMembers(JSON.parse(location?.state?.groupData?.groupMember));
      setGroupId(location?.state?.id);
    }
  }, []);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    setImagePreview(file);
    if (file) {
      const imageURL = URL.createObjectURL(file);
      console.log("imageURL>>>>>>>", imageURL);
      setImgSrc(imageURL);
    }
  };

  const handleUploadImage = () => {
    ImageRef.current.click();
  };

  const handleChangeMembers = (e) => {
    const value = e.target.value.split(",");
    setMembers([value]);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault();

    if (!input.group_name || !members) {
      toast.error("Group Name and Members are required fields");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("image", imagePreview);
    formData.append("name", input.group_name);
    formData.append("members", JSON.stringify(members));
    formData.append("topic", input.topic);
    formData.append("description", input.description);

    try {
      const result = await axios.post(
        `${Base_Url}/character/create_group`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("result>>>>>", result);
      if (result.data.success === true) {
        toast.success("group created successfully");
        navigate("/home");
      }
    } catch (error) {
      console.log("error>>>", error);
      toast.error(error.message);
    }
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();

    if (!input.group_name || !members) {
      toast.error("Group Name and Members are required fields");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("image", imagePreview);
    formData.append("group_name", input.group_name);
    formData.append("members", JSON.stringify(members));
    formData.append("topic", input.topic);
    formData.append("description", input.description);

    try {
      const result = await axios.put(
        `${Base_Url}/character/update_group/${groupId}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      console.log("result>>>>>", result);
      if (result.data.success === true) {
        toast.success("group updated successfully");
        navigate("/home");
      }
    } catch (error) {
      console.log("error>>>>>>", error);
      toast.error(error.message);
    }
  };

  // delete character
  const handleDeleteGroup = async (e) => {
    e.preventDefault();

    try {
      const result = await axios.delete(
        `${Base_Url}/character/delete_group/${groupId}`
      );
      console.log("result>>>>>", result);
      if (result.data.success === true) {
        toast.success("group deleted successfully");
        navigate("/home");
      }
    } catch (error) {
      console.log("error>>>>>>", error);
      toast.error(error.response.data.error);
    }
  };

  useEffect(() => {
    const getuser = async () => {
      const user = await axios.get(`${Base_Url}/users/getuser/${userId}`);
      console.log("Groupuser>>>>>>>>>", user);
      // setUserCharacter(user.data.user.chatRoom)
    };
    getuser();
  }, [Base_Url, userId]);

  // get user characters
  useEffect(() => {
    const getcharacters = async () => {
      try {
        const result = await axios.get(
          `${Base_Url}/character/getMyCharacters/${userId}`
        );
        console.log("getgroupuserresult>>>>>>>", result);
        setUserCharacter(result.data.characters);
      } catch (error) {
        console.log("error>>>>>", error);
      }
    };
    getcharacters();
  }, [Base_Url, userId]);

  const onSelect = (selectedList, selectedItem) => {
    setMembers(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setMembers(selectedList);
  };

  const options =
    userCharacter &&
    userCharacter?.map((character) => ({
      name: character.name,
      id: character._id,
    }));

  return (
    <>
      <ToastContainer />
      <div className="character_outer inner-screen">
        <div className="character_head">
          {!groupId ? <h2>グループを作る</h2> : <h2>グループを編集する</h2>}
        </div>
        <div className="character_fields">
          <div className="character_form">
            <form>
              <div className="form-group top-image">
                <div className="one-box">
                  <label for="image">アイコン</label>
                  {imagePreview === "" && location?.state == null && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="89"
                      height="89"
                      viewBox="0 0 89 89"
                      fill="none"
                    >
                      <path
                        d="M73.1555 75.8246C71.4652 71.0935 67.7407 66.9129 62.5596 63.9312C57.3785 60.9495 51.0303 59.3333 44.4997 59.3333C37.969 59.3333 31.6209 60.9495 26.4398 63.9312C21.2586 66.9129 17.5341 71.0935 15.8439 75.8246"
                        stroke="#33363F"
                        stroke-width="6"
                        stroke-linecap="round"
                      />
                      <circle
                        cx="44.5003"
                        cy="29.6667"
                        r="14.8333"
                        stroke="#33363F"
                        stroke-width="6"
                        stroke-linecap="round"
                      />
                    </svg>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    onChange={handleImageChange}
                    ref={ImageRef}
                    style={{ display: "none" }}
                  />
                  <img
                    src={
                      imgSrc ||
                      `${Base_Url}/character/${location?.state?.groupData?.groupImage}`
                    }
                    alt=""
                    style={{ maxWidth: "102px", maxHeight: "102px" }}
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleUploadImage}
                >
                  アップロード
                </button>
              </div>
              <div className="form_group">
                <label for="name">グループ名*</label>
                <p>
                  ハンドルは一意でなければならず、4～20文字で、アルファベット、数字、ダッシュまたはアンダースコアを含むことができる。.
                </p>
                <input
                  type="text"
                  name="group_name"
                  className="form-control"
                  value={input.group_name}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="form_group">
                <label for="name">メンバー追加*</label>
                <p>
                  このグループに追加したいキャラクターの名前を入力してください。(最大3名まで）
                </p>
                {/* <input type='text' className='form-control' name='members' onChange={handleChangeMembers} /> */}
                <Multiselect
                  options={options}
                  selectedValues={members}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  className="member_field"
                />
              </div>
              <div className="form_group">
                <label for="name">トピックの追加</label>
                <p>
                  この部屋で何が起こるべきか。キャラクターはそれに従おうとする.
                </p>
                <input
                  type="text"
                  name="topic"
                  className="form-control"
                  value={input.topic}
                  onChange={handleChangeInput}
                />
              </div>
              <div className="form_group">
                <label for="description">説明</label>
                <p>
                  ハンドルは一意でなければならず、4～20文字で、アルファベット、数字、ダッシュまたはアンダースコアを含むことができる。.
                </p>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  value={input.description}
                  onChange={handleChangeInput}
                />
              </div>
            </form>
            {!groupId ? (
              <div className="character_button">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateGroup}
                >
                  作成
                </button>
              </div>
            ) : (
              <div className="edit_button">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleEditGroup}
                >
                  保存
                </button>
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={handleDeleteGroup}
                >
                  キャラクターを削除する
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupCharacter;
