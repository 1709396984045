import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Callback = () => {

const Discord_client_id =process.env.REACT_APP_DISCORD_CLIENT_ID;
const Discord_Client_secret = process.env.REACT_APP_DISCORD_CLIENT_SECRET;
const REDIRECT_URI = process.env.REACT_APP_DISCORD_REDIRECT_URI;

  const navigate = useNavigate();

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const code = new URLSearchParams(window.location.search).get('code');
      console.log("code>>>>",code)

      if (code) {
        try {
            const response = await axios.post(
              'https://discord.com/api/oauth2/token',
              `code=${code}&redirect_uri=${REDIRECT_URI}&client_id=${Discord_client_id}&client_secret=${Discord_Client_secret}&scope=email&grant_type=authorization_code`,
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
          
            console.log('response>>>>>>>', response.data);
            navigate('/home');
          } catch (error) {
            console.error('Error exchanging code for token:', error);
            // Handle error, possibly redirect to an error page
          }
      }
    };

    handleOAuthCallback();
  }, []);

  return <div>Loading...</div>;
};

export default Callback;